<template>
  <div class="mx-auto">
    <TitleWrapper
      :filter-option="false"
      title="Notifications"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div class="settings-bar mt-5 px-7">
      <div class="font-roboto text-text-color text-lg pt-7 mb-14">
        {{ $t('notifications.What Notifications You Receive') }}
      </div>

      <div class="flex flex-col justify-between border-b border-bt-color pb-7">
        <div class="flex justify-between">
          <div class="font-roboto text-text-color text-base">
            {{ $t('notifications.Teachers Activity') }}
          </div>
          <div
            class="py-2"
            :class="teacherActivitySettings ? 'rotate-180' : 'transition'"
            @click="rotateTeacherCaret"
          >
            <Icon color="border-color-grey-strong" icon="caretDown" />
          </div>
        </div>
        <div v-if="teacherActivitySettings" class="drop-down-teacher-activity mt-14">
          <div class="flex justify-between">
            <div class="font-roboto text-text-color text-base">
              {{ $t('notifications.Teachers') }}
            </div>
            <div>
              <label class="switch">
                <input type="checkbox" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <div class="flex justify-between pt-14">
            <div class="font-roboto text-text-color text-base">
              {{ $t('notifications.Students') }}
            </div>
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="flex justify-between pt-14 pb-7">
            <div class="font-roboto text-text-color text-base">
              {{ $t('notifications.Staff') }}
            </div>
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>

      <div class="flex flex-col justify-between border-b border-bt-color pb-7 pt-7">
        <div class="flex justify-between">
          <div class="font-roboto text-text-color text-base">
            {{ $t('notifications.Notice Board') }}
          </div>
          <div
            class="py-2"
            :class="noticeBoardSettings ? 'rotate-180' : 'transition'"
            @click="rotateNoticeBoardCaret"
          >
            <Icon color="border-color-grey-strong" icon="caretDown" />
          </div>
        </div>
        <div v-if="noticeBoardSettings" class="drop-down-teacher-activity mt-14">
          <div class="flex justify-between">
            <div class="font-roboto text-text-color text-base">
              {{ $t('notifications.Teachers') }}
            </div>
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="flex justify-between pt-14">
            <div class="font-roboto text-text-color text-base">
              {{ $t('notifications.Students') }}
            </div>
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="flex justify-between pt-14 pb-7">
            <div class="font-roboto text-text-color text-base">
              {{ $t('notifications.Staff') }}
            </div>
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>

      <div class="flex flex-col justify-between border-b border-bt-color pb-7 pt-7">
        <div class="flex justify-between">
          <div class="font-roboto text-text-color text-base">
            {{ $t('notifications.Birthday') }}
          </div>
          <div
            class="py-2"
            :class="birthdaySettings ? 'rotate-180' : 'transition'"
            @click="rotateBirthdayCaret"
          >
            <Icon color="border-color-grey-strong" icon="caretDown" />
          </div>
        </div>
        <div v-if="birthdaySettings" class="drop-down-teacher-activity mt-14">
          <div class="flex justify-between">
            <div class="font-roboto text-text-color text-base">
              {{ $t('notifications.Teachers') }}
            </div>
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="flex justify-between pt-14">
            <div class="font-roboto text-text-color text-base">
              {{ $t('notifications.Students') }}
            </div>
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="flex justify-between pt-14 pb-7">
            <div class="font-roboto text-text-color text-base">
              {{ $t('notifications.Staff') }}
            </div>
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>

      <div class="flex flex-col justify-between border-b border-bt-color pb-7 pt-7">
        <div class="flex justify-between">
          <div class="font-roboto text-text-color text-base">
            {{ $t('notifications.Class') }}
          </div>
          <div
            class="py-2"
            :class="classSettings ? 'rotate-180' : 'transition'"
            @click="rotateClassCaret"
          >
            <Icon color="border-color-grey-strong" icon="caretDown" />
          </div>
        </div>
        <div v-if="classSettings" class="drop-down-teacher-activity mt-14">
          <div class="flex justify-between">
            <div class="font-roboto text-text-color text-base">
              {{ $t('notifications.Teachers') }}
            </div>
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="flex justify-between pt-14">
            <div class="font-roboto text-text-color text-base">
              {{ $t('notifications.Students') }}
            </div>
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="flex justify-between pt-14 pb-7">
            <div class="font-roboto text-text-color text-base">
              {{ $t('notifications.Staff') }}
            </div>
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import Icon from '@components/icons/icon.vue'

export default {
  components: {
    TitleWrapper,
    Icon,
  },
  data() {
    return {
      teacherActivitySettings: false,
      noticeBoardSettings: false,
      birthdaySettings: false,
      classSettings: false,
    }
  },
  methods: {
    rotateTeacherCaret() {
      this.teacherActivitySettings = !this.teacherActivitySettings
    },
    rotateNoticeBoardCaret() {
      this.noticeBoardSettings = !this.noticeBoardSettings
    },
    rotateBirthdayCaret() {
      this.birthdaySettings = !this.birthdaySettings
    },
    rotateClassCaret() {
      this.classSettings = !this.classSettings
    },
  },
}
</script>

<style lang="scss" scoped>
.rotate-180 {
  transition: 0.3s;
  -moz-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
}

.transition {
  transition: 0.3s;
}

.settings-bar {
  background-color: var(--bg-color-white);
}

.border-bt-color {
  border-bottom-color: var(--border);
}

.base {
  background: var(--green-light);
  opacity: 1;
}

.campusBase {
  color: #1d273e;
}
.select {
  color: #fff;
  background-color: #267c26;
}
.green {
  background: #267c26;
}
.system {
  color: #fff;
}

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 22px;
}
.switch input {
  width: 0;
  height: 0;
  opacity: 0;
}
.slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  background-color: #ccc;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.slider::before {
  position: absolute;
  bottom: 3px;
  left: 3px;
  width: 17px;
  height: 16px;
  content: '';
  background-color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
input:checked + .slider {
  background-color: var(--primary-green);
}
input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-green);
}
input:checked + .slider::before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round::before {
  border-radius: 50%;
}
</style>
